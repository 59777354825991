// Custom styles for this template

.aside-menu {
  .nav-tabs .nav-link,
  .callout {
    border-radius: 0;
  }
}

.sidebar {
  .sidebar-nav, ul.nav {
    width: $sidebar-width - 16px;
  }
}

// Here you can add other styles

body {
  font-family: Verdana, Arial, Helvetica, sans-serif;
}